.tfa-form-wrapper {
  width: 450px;
  background: #ffffff;
  padding: 32px 40px;
}

.tfa-form-body {
  text-align: center;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: #111827;
  text-align: left;
  margin-bottom: 24px;
}

.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  text-align: left;
  margin-bottom: 40px;
}

.btn,
.btn:focus {
  height: 48px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;

  border-radius: 4px;

  margin-top: 24px;
}

.btn:hover {
  color: #ffffff;
  opacity: 0.7;
}

.error-msg {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  color: #e14c4c;

  margin-top: 10px;
  text-align: center;
}
